<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="detail">
      <b-card>
        <div class="pb-3">
          <b-button variant="primary" class="btn-icon mr-1" @click="() => $router.go(-1)">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button variant="success" class="btn-icon mr-1">
            <feather-icon icon="RefreshCwIcon" size="16" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-between">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-avatar class="mb-1 mr-2" size="6rem" :src="detail.user.avatar_url"
                  :initials="detail.user.initial_letters"></b-avatar>
                <div>
                  <h4 class="mb-0">
                    {{ detail.user.full_name }}
                  </h4>
                  <span class="card-text"><strong>Email:</strong> {{ detail.user.email }}</span>
                  <br />
                  <span class="card-text">
                    <strong>Telefono:</strong> {{ detail.user.phone }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Registrado desde:</strong>
                    {{ dateFormat(detail.user.created_at) }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Tipo de cuenta:</strong>
                    {{ detail.user.account_type }}
                  </span>
                  <br />
                </div>
              </div>
              <div class="text-right">
                <!--verified element-->
                <div v-if="detail.user.verified">
                  <b-badge variant="success" class="ml-1">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-25">Verificado</span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="ml-1">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                    <span class="align-middle ml-25">No verificado</span>
                  </b-badge>
                </div>
              </div>
            </div>

            <!-- User Stats -->
            <div class="d-flex justify-content-end mb-2">
              <b-row>
                <b-col cols="12" class="mb-2" v-if="
                  detail.status === 'pending' || detail.status === 'in_review'
                ">
                  <b-button variant="danger" class="mr-2" @click="approve('rejected')"
                    v-if="permissions_requerid.includes('reject-withdrawal')">
                    Rechazar
                  </b-button>
                  <b-button variant="success" @click="approve('completed')"
                    v-if="permissions_requerid.includes('approve-withdrawal')">
                    Completar
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <h2 class="mb-2">Estado actual: {{ detail.status_text }}</h2>
                  <h4>Retiro # {{ detail.reference }}</h4>
                  <h4>Monto a retirar: {{ detail.amount }}</h4>
                  <h4>Tipo de retiro: {{ detail.payment_method.name }}</h4>
                  <h4>Recibira: {{ detail.received_amount }}</h4>
                  <h4>
                    Deposito en: {{ detail.platform_account.values.holder }}
                  </h4>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { inputAlert, confirmAlert } from '@/helpers/utils'
import { verifyPermission } from '@/router';
moment.locale('es')
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

import numberMixins from '@/mixins/numberMixins'

export default {
  components: {
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCardText,
    BTab,
    BTabs,
  },
  mixins: [numberMixins],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      loading: (state) => state.withdrawals.loading,
      detail: (state) => state.withdrawals.detail,
    }),
  },
  data() {
    return {
      showDocument: false,
      urlDocument: '',
      verifyFile: null,
      permissions_requerid: []
    }
  },
  watch: {},

  mounted() {
    // Verify
    let approve_withdrawal = verifyPermission({ namePerm: 'approve-withdrawal' })
    let reject_withdrawal = verifyPermission({ namePerm: 'reject-withdrawal' });
    let permissions_requerid_verify = []
    if (approve_withdrawal) {
      permissions_requerid_verify.push("approve-withdrawal")
    }
    if (reject_withdrawal) {
      permissions_requerid_verify.push("reject-withdrawal")
    }

    this.permissions_requerid = permissions_requerid_verify

  },
  methods: {
    async documentVerify(id, status, type) {
      const res = await this.$store.dispatch('withdrawals/documentVerify', {
        id,
        status: status,
        type,
      })
    },
    getNameFileType(type) {
      switch (type) {
        case 'front':
          return 'Frente'
        case 'backDocument':
          return 'Trasera'
        case 'selfie':
          return 'Selfie'
        default:
          return 'No definido'
      }
    },
    showFile(doc) {
      this.urlDocument = doc.url
      this.showDocument = true
      this.verifyFile = doc
    },
    getDetail() {
      this.$store.dispatch('withdrawals/getDetail', {
        id: this.$route.params.id,
      })
    },
    async approve(status) {
      let res = {
        isConfirmed: false,
        value: '',
      }
      if (status !== 'completed') {
        res = await inputAlert(
          'Rechazar retiro de dinero',
          'Ingrese el montivo del rechazo'
        )
      } else {
        // res = await confirmAlert(
        //   'Aprobar retiro de dinero',
        //   '¿ Esta seguro de aprobar estes retiro de dinero ?'
        // )
        res = await inputAlert(
          'Aprobar retiro de dinero',
          'Ingrese la referencia del deposito'
        )
      }

      if (!res.isConfirmed) return

      this.$store.dispatch('withdrawals/approve', {
        id: this.$route.params.id,
        status: status,
        motive_rejected: status !== 'completed' ? res.value : '',
        reference_transfer_money: status === 'completed' ? res.value : '',
      })
    },
  },
  created() {
    this.getDetail()
  },
}
</script>
